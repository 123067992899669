import axios from 'axios'

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    // other configurations
})

axiosInstance.interceptors.request.use(
    config => {
        config.headers['Accept'] = 'application/json';
        return config
    },
    (error) => {
        return Promise.reject(error)
    },
)

export default axiosInstance