import React from "react";
import { Link } from "react-router-dom";
import FooterLogo from "../img/footer-logo.png";

function Footer(){
    return (
        <div className="footer_wrap">
            <div className="grad_footer">
            <div className="container">
                <div className="footer_row">
                    <div className="footer_logo">
                        <img  src={FooterLogo} alt="Logo" />
                    </div>
                    <div className="footer_links">
                        <ul>
                            <li><a href="https://meganhale.co/privacy-policy">Privacy policy</a></li>
                            <li><a href="https://meganhale.co/terms-and-conditions">Terms & Conditions</a></li>
                            <li><a href="https://meganhale.co/about#our-commitment">Our commitment to DEI</a></li>
                        </ul>
                    </div>
            </div>
             <p className="dream">Dream Money is a Subsidiary of Megan Hale, LLC</p>
            </div>
            </div>
            <div className="copyright_f">
            <p>©️ 2024 Megan Hale, LLC.<span>&nbsp; &nbsp; &nbsp; |&nbsp; &nbsp; &nbsp; Mailing Address: PO Box 1084, Bellevue, NE 68005 </span></p>
            </div>
        </div>

    );
}

export default Footer;