import { useEffect, useRef, useState } from "react";
import { StepEight, StepEleven, StepFive, StepFour, StepNine, StepOne, StepSeven, StepSix, StepTen, StepThree, StepTwo } from "./StepForms";
import Header from "../Header";
import Footer from "../Footer";

const DreamMoneyForm = () => {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        name: "",
        company_name: "",
        email: "",
        teams_month_cost: "",
        month_invest: "",
        month_expenses: "",
        month_personal_expenses: "",
        feeling: "",
        identifier: ""
    })
    const [formError, setFormError] = useState({
        name: "",
        company_name: "",
        email: "",
        teams_month_cost: "",
        month_invest: "",
        month_expenses: "",
        month_personal_expenses: ""
    })
    const dreamMoneyStorage = useRef("");

    const handleStep = (newStep, identifier = undefined) => {
        if (identifier !== undefined) {
            setFormData({
                ...formData,
                identifier
            })
        }
        setStep(newStep)
    }

    const handleInput = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
    }

    const handleUnload = (e) => {
        if (dreamMoneyStorage.current === "") {
            localStorage.removeItem("dreamMoney");
        } else {
            localStorage.setItem("dreamMoney", dreamMoneyStorage.current);
        }
    }

    useEffect(() => {
        let dreamMoneyForm = localStorage.getItem("dreamMoney")
        if (dreamMoneyForm) {
            dreamMoneyForm = JSON.parse(dreamMoneyForm);
            if (dreamMoneyForm.step) {
                setStep(dreamMoneyForm.step);
                setFormData(dreamMoneyForm.formData);
                // setFormError(dreamMoneyForm.formError);
            }
        }
        window.addEventListener('beforeunload', handleUnload)
    }, []);

    useEffect(() => {
        if (step < 11) {
            dreamMoneyStorage.current = JSON.stringify({
                formData,
                formError,
                step
            })
        } else {
            dreamMoneyStorage.current = "";
        }
    }, [step, formData, formError])

    return (
        <>
            <Header></Header>
            <div className='calculator-wrap'>
                {/* <div className='container'> */}
                <StepOne step={step} handleStep={handleStep} handleInput={handleInput} formData={formData} formError={formError} setFormError={setFormError} />
                {/* {step === 2 && <StepTwo step={step} handleStep={handleStep} handleInput={handleInput} formData={formData} formError={formError} setFormError={setFormError} />}
            {step === 3 && <StepThree step={step} handleStep={handleStep} handleInput={handleInput} formData={formData} formError={formError} setFormError={setFormError} />}
            {step === 4 && <StepFour step={step} handleStep={handleStep} handleInput={handleInput} formData={formData} formError={formError} setFormError={setFormError} />}
            {step === 5 && <StepFive step={step} handleStep={handleStep} handleInput={handleInput} formData={formData} formError={formError} setFormError={setFormError} />}
            {step === 6 && <StepSix step={step} handleStep={handleStep} handleInput={handleInput} formData={formData} formError={formError} setFormError={setFormError} />}
            {step === 7 && <StepSeven step={step} handleStep={handleStep} handleInput={handleInput} formData={formData} formError={formError} setFormError={setFormError} />}
            {step === 8 && <StepEight step={step} handleStep={handleStep} handleInput={handleInput} formData={formData} formError={formError} setFormError={setFormError} />}
            {step === 9 && <StepNine step={step} handleStep={handleStep} handleInput={handleInput} formData={formData} formError={formError} setFormError={setFormError} />}
            {step === 10 && <StepTen step={step} handleStep={handleStep} handleInput={handleInput} formData={formData} formError={formError} setFormError={setFormError} />}
            {step === 11 && <StepEleven step={step} handleStep={handleStep} handleInput={handleInput} formData={formData} formError={formError} setFormError={setFormError} />} */}
                {/* </div> */}
            </div>
            <Footer></Footer>
        </>
    );
}

export default DreamMoneyForm;