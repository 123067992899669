import { Outlet } from "react-router-dom";

import { clarity } from 'react-microsoft-clarity';
import ReactGA from 'react-ga';
import './App.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  clarity.init('lisb95sujx');
  ReactGA.initialize('G-L0LMLM4S7N');
  return (
    <div className="App">
      <Outlet />
    </div>
  );
}

export default App;
