import React, { useEffect, useState } from "react";
import ReactPlayer from 'react-player';
// import BackArrow from '../../img/back-arrow.svg';
// import LongArrowD from '../../img/long-arrow-d.svg';
// import EqualLine from '../../img/mingcute-2line.svg';
// import InfoIcon from '../../img/quill_info.svg';
import CloseIcon from '../../img/charm_cross.svg';
// import businessExpensesVtt from '../../img/vtt/business-expenses.vtt';
// import teamExpensesVtt from '../../img/vtt/team-expense.vtt';
// import investmentExpensesVtt  from "../../img/vtt/investment-expenses.vtt";
// import mvrKeyNeedsVtt from '../../img/vtt/mvr-7-key-needs.vtt';
// import normalizeVtt from '../../img/vtt/normalize.vtt';
// import personalPayVtt from '../../img/vtt/personal-pay.vtt';
// import LongArrow from '../../img/long-arrow.svg';
import VideoTeam from '../../img/calculating-team.mp4';
// import VideoInvest from '../../img/calculating-investment.mp4';
// import VideoBusiness from '../../img/calculating-business.mp4';
// import VideoPersonal from '../../img/calculating-personal.mp4';
import DreamLogo from '../../img/dream-logo.png';
import IntroImage from '../../img/inro-img.jpg';
import Teamexpense from '../../img/team-expense.svg';
import Monthlyreinvestment from '../../img/monthly-reinvestment.svg';
import Businessexpense from '../../img/business-expense.svg';
import Personalexpense from '../../img/personal-expense.svg';
import Videofull from '../../img/funded.mp4'
import { emailRegex, numericalRegex } from "../properties";
import { basicStep, businessExpenses, feelings, finalStep, investmentExpenses, notify, personalExpenses, showDetails, showRevenue, teamExpenses } from "../../actions/stepForm";

const formatter = new Intl.NumberFormat('en-US');

const LightBox = ({ vttSrc, src, Wrapper = 'div', zIndex = 100, clickHandler }) => {
  return (
    <Wrapper>
      <div className='modal-wrapper' style={{
        position: 'fixed',
        top: '0',
        left: '0',
        height: '100vh',

        width: '100vw',
        cursor: 'pointer',
        zIndex
      }}>

        <div className='video-modal'>
          <div className='close-icon' onClick={() => clickHandler(false)}><img src={CloseIcon} alt="close" /></div>

          <ReactPlayer width='100%' height='100%' url={src} playsinline={false} loop={false} playing={true} controls={true}
            config={{
              file: {
                tracks: [
                  {
                    kind: 'subtitles',
                    src: vttSrc,
                    srcLang: 'en',
                    default: true,
                  }
                ],
                attributes: { controlsList: 'track nodownload noplaybackrate' }
              }
            }}
          >
          </ReactPlayer>

        </div>
      </div>
    </Wrapper>
  );
};

export const StepOne = ({ step, formData, formError, handleStep, handleInput, setFormError }) => {
  const [disabled, setDisabled] = useState(false);
  const [errors, setErrors] = useState([]);
  const nextStep = async () => {
    let check = 1;
    let tempError = formError;
    if (formData.email === "") {
      check = 0;
      tempError = {
        ...tempError,
        email: "This field can not be empty"
      }
    } else if (!formData.email.match(emailRegex)) {
      check = 0;
      tempError = {
        ...tempError,
        email: "Please enter a valid email."
      }
    }

    console.log(formData.email.match(emailRegex));

    if (check) {
      setDisabled(true);
      const resp = await basicStep(formData);
      if (resp && resp.error) {
        let errors = [];
        if (typeof resp.error === 'string') {
          errors.push(resp.error);
        } else {
          if (Object.keys(resp.error).length) {
            for (let err of Object.keys(resp.error)) {
              errors.push(resp.error[err]);
            }
          }
        }
        setErrors(errors);
        setDisabled(false);
      } else {
        setFormError({
          ...formError,
          email: ""
        });
        handleStep(step + 1, resp?.data?.data?.identifier || "")
      }
    } else {
      setFormError(tempError);
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <div className='containers'>
      <div className='step-2 steps m-step2-eamil active'>
        <div className='step2-containers'>
          <h2>Hey there, Megan!</h2>
           <h6>And welcome to the Dream Money Calculator for revenue that’s:</h6>
            <img src={DreamLogo} className="img-fluid" alt="" />
            <div className='input-form'>
            <h3>What's your best email?</h3>
              {errors.length ? <div className="d-flex" style={{ marginBottom: "10px" }}>
                {errors.map(err => <span className="invalid-feedback">
                  {err}
                </span>)}
              </div> : <></>}
              <div className="max-76">
                <input onChange={handleInput} type='email' className='form-control' placeholder='john@email.com' name="email" value={formData.email} />
                <span className="invalid-feedback">
                  {formError.email}
                </span>
                <p>**By clicking next you agree to our <a href="https://meganhale.co/dmc-terms-and-conditions" target="_blank" rel="noreferrer" >terms and conditions</a>.</p>
              </div>
              {/* onClick={(e) => e.preventDefault()} */}
            </div>
            <button className='btn btn-primary' onClick={nextStep} disabled={disabled}>Next</button>
         
        </div>
      </div>
    </div>

<div className='step-3 steps m-intro-wrapper active'>
<div className='m-intro-wrap'>
    <div className="m-intro-img">
       <img src={IntroImage} className="img-fluid" />
    </div>
  <div className='m-intro-content'>
    <div>
    <h2>Thanks, Megan</h2>
    <p>In less than 5 minutes, we’re going to calculate your Minimum Viable Revenue -
      a unique-to-you number that covers 7 key financial needs we all have as entrepreneurs.
      This number will tell you exactly how much your business needs to earn to get off the month-to-month cash crunch for good!</p>
    <button onClick={() => handleStep(nextStep)} className='btn btn-primary'>I’m So Ready</button>
    </div>
  </div>
</div>

</div>
<div className='step-4 steps m-await-wrapper active'>
<div className='container'>
     <div className="m-await-head">
      <h2>Dream Money Awaits!</h2>
      <p>On the following page, you’ll enter <strong>4 unique numbers</strong> based on your current expenses 💸</p>
     </div>
     <div className="m-await-box">
      <p>All your entries are <strong>100% confidential</strong> and will only be used to calculate your most accurate Minimum Viable Revenue (aka, your MVR).</p>
      <button onClick={() => handleStep(nextStep)} className='btn btn-primary'>Got It!</button>
     </div>
</div>

</div>
<div className='step-5 steps active'>
<div className="m-mvr-wrap">
   <div className="container">
     <div className="mvr_head">
       <h2>Let’s Calculate Your MVR</h2>
       <h6>Enter your 4 unique numbers below</h6>
       <p>Any entry you make will be saved in case you need to click away and come back. Feel free to enter your best estimated numbers 💸  However, the more precise you are, the more accurate your results 😉</p>
     </div>
     <div className="mvr_calc_row">
         <div className="single_calc">
           <div className="img">
               <img src={Teamexpense} className="img-fluid" alt="" />
           </div>
           <h4>Enter Your Monthly Team Expenses <a href="#">(Learn More)</a></h4>
           <input  type='text' className='form-control' placeholder='Write monthly team expense' />
         </div>
         <div className="single_calc light-cream">
           <div className="img">
           <img src={Monthlyreinvestment} className="img-fluid" alt="" />
           </div>
           <h4>Enter Your Monthly Reinvestment Expenses  <a href="#">(Learn More)</a></h4>
           <input  type='text' className='form-control' placeholder='Write monthly reinvestment expense' />
         </div>
         <div className="single_calc light-green">
           <div className="img">
           <img src={Businessexpense} className="img-fluid" alt="" />
           </div>
           <h4>Enter Your Monthly Business Expenses <a href="#">(Learn More)</a></h4>
           <input  type='text' className='form-control' placeholder='Write monthly business expense' />
         </div>
         <div className="single_calc light-gray">
           <div className="img">
           <img src={Personalexpense} className="img-fluid" alt="" />
           </div>
           <h4>Enter Your Required Monthly Personal Expenses <a href="#">(Learn More)</a></h4>
           <input  type='text' className='form-control' placeholder='Write monthly required monthly personal expense' />
         </div>
     </div>
     <div className="text-center">
     <button onClick={() => handleStep(nextStep)} className='btn btn-primary'>Calculate My MVR</button>
     </div>
   </div>
</div>
 <div className="mvr-help-wrap">
  <div className="container">
     <h6>Need help gathering your numbers?  </h6>
     <h2>Grab our easy-peasy mini-course <a target="_blank" href="http://meganhale.co/numbers">right here!</a></h2>
  </div>
 </div>

</div>

<div className="modal-wrapper">
<div className="close-icon"><img src={CloseIcon}   alt="close" /></div>
  <div className="video-modal">
          <video src={VideoTeam} preload="auto"  autoplay="" controls="" loop="" ></video>
  </div>

  <div className="modal-body">

  <div className='team-expenses'>
            <h3 className='h2'>1. What are your monthly team expenses for {formData.company_name}?</h3>
            <div className="team-expenses-row">
              <ul>
                <li>Your team expenses include any contract team members who are on a monthly retainer or any team members who are considered part-time or full-time employees. </li>
              </ul>
              <ul>
                <li>When entering your team expenses, enter a quick total estimate for how much you typically pay your team members each month (including employee taxes, if applicable).  </li>
              </ul>
              <ul>
                <li>For example, a business’s team expenses could include:
                  <ul>
                    <li>Virtual Assistant ($1000)</li>
                    <li>Social Media Manager ($2500)</li>
                    <li>Director of Ops ($4500)</li>
                    <li>CPA ($250)</li>
                  </ul>
                  <p>Total: $8250</p>
                </li>
              </ul>
            </div>
            <div className="team-expenses-notes">
              <h2>2 notes</h2>
              <div className="team-expenses-notes-row">
                <div className="item">
                  <p>If you are currently on your business’s payroll, do NOT include yourself in this number. You’ll be asked to fill out your personal pay needs in a later question.</p>
                </div>
                <div className="item">
                  <p>If you currently do not have team members, our calculator requires a <b>$500 minimum</b> so we can make sure we’re anticipating your need to hire to sustainably grow your business.</p>
                </div>
              </div>
            </div>
           
              <button className='btn btn-primary btn-close' >Close</button>
            </div>
  </div>
       
</div>

<div className="modal-wrapper">
<div className="close-icon"><img src={CloseIcon}   alt="close" /></div>


  <div className="modal-body">

  <div className='inactive'>
            <h3 className='h2'>Do you need help gathering your numbers?</h3>
            <p>Click here to check out our quick mini course to gather your personal expenses.</p>
         <button className='btn btn-primary' >Get it here for $17</button>
            </div>
  </div>
       
</div>
</>
  );
};



// export const StepTwo = ({ step, handleStep }) => {
//   const nextStep = step + 1;
//   const [showTool, setTool] = useState(true);
//   const onClick = () => setTool(false);
//   useEffect(() => {
//     window.scrollTo(0, 0)
//   }, [])
//   return (
//       <div className='step-3 steps active'>
//         <div className='m-intro-wrap'>
//             <div className="m-intro-img">
//                <img src={IntroImage} className="img-fluid" />
//             </div>
//           <div className='m-intro-content'>
//             <div>
//             <h2>Thanks, Megan</h2>
//             <p>In less than 5 minutes, we’re going to calculate your Minimum Viable Revenue -
//               a unique-to-you number that covers 7 key financial needs we all have as entrepreneurs.
//               This number will tell you exactly how much your business needs to earn to get off the month-to-month cash crunch for good!</p>
//             <button onClick={() => handleStep(nextStep)} className='btn btn-primary'>I’m So Ready</button>
//             </div>
//           </div>
//         </div>

//       </div>
   
//   )
// }


// export const StepThree = ({ step, formData, formError, handleStep, handleInput, setFormError }) => {
//   const nextStep = () => {
//     let check = 1;
//     let tempError = formError;
//     if (formData.name === "") {
//       check = 0;
//       tempError = {
//         ...tempError,
//         name: "Please tell us your name."
//       };
//     }
//     if (formData.company_name === "") {
//       check = 0;
//       tempError = {
//         ...tempError,
//         company_name: "Please tell us your business name."
//       }
//     }

//     if (check) {
//       handleStep(step + 1);
//       setFormError({
//         ...formError,
//         name: "",
//         company_name: ""
//       });
//     } else {
//       setFormError(tempError);
//     }
//   }

//   useEffect(() => {
//     window.scrollTo(0, 0)
//   }, [])
//   return (
//     <div className='containers'>
//       <div className='step-2 steps m-step2-eamil active'>
//         <div className='step2-containers '>
//            <h2>Hey there, Megan!</h2>
//            <h6>And welcome to the Dream Money Calculator for revenue that’s:</h6>
//             <img src={DreamLogo} className="img-fluid" alt="" />
//           <div className='input-form'>
//             <h3>What's your best email?</h3>
//             <input onChange={handleInput} type='text' className='form-control' placeholder='Write your email' name="company_name" value={formData.company_name} />
//             <span className="invalid-feedback">
//               {formError.company_name}
//             </span>
//             <p>**By clicking next you agree to our <a href="https://meganhale.co/dmc-terms-and-conditions" target="_blank" rel="noreferrer" >terms and conditions</a>.</p>
//           </div>
         
//           <button className='btn btn-primary' onClick={nextStep}>Next</button>
//         </div>
//       </div>
//     </div>
//   )
// }


// export const StepFour = ({ step, formData, formError, handleStep, handleInput, setFormError }) => {
//   const [disabled, setDisabled] = useState(false);
//   const [openLightbox, setOpenLightbox] = useState(false);
//   const [errors, setErrors] = useState([]);
//   const prevStep = step - 1;
//   const nextStep = async () => {
//     let check = 1;
//     let tempError = formError;
//     if (formData.teams_month_cost === "" || formData.teams_month_cost < 500) {
//       check = 0;
//       tempError = {
//         ...tempError,
//         teams_month_cost: "Please enter a numerical number $500 or greater."
//       }
//     } else if (!formData.teams_month_cost.match(numericalRegex)) {
//       check = 0;
//       tempError = {
//         ...tempError,
//         teams_month_cost: "Please enter a numerical number $500 or greater."
//       }
//     }

//     if (check) {
//       setDisabled(true);
//       const resp = await teamExpenses(formData);
//       if (resp && resp.error) {
//         let errors = [];
//         if (typeof resp.error === 'string') {
//           errors.push(resp.error);
//         } else {
//           if (Object.keys(resp.error).length) {
//             for (let err of Object.keys(resp.error)) {
//               errors.push(resp.error[err]);
//             }
//           }
//         }
//         setErrors(errors);
//         setDisabled(false);
//       } else {
//         setFormError({
//           ...formError,
//           teams_month_cost: ""
//         });
//         handleStep(step + 1);
//       }
//     } else {
//       setFormError(tempError);
//     }
//   }

//   useEffect(() => {
//     if (formData.identifier === "") {
//       handleStep(1)
//     }
//   }, [formData.identifier])
//   useEffect(() => {
//     window.scrollTo(0, 0)
//   }, [])
//   return (
//     <div className='container'>
//       <div className='step-5 steps active'>
//         <div className='step-container'>
//           <a href='/' className='back-step' onClick={(e) => {
//             e.preventDefault();
//             handleStep(prevStep)
//           }}> <img src={BackArrow} alt="go back" /> Back</a>
//           <div className='team-expenses'>
//             <h2>Let’s dive into the numbers</h2>
//             <h3 className='h2'>1. What are your monthly team expenses for {formData.company_name}?</h3>
//             <div className="team-expenses-row">
//               <ul>
//                 <li>Your team expenses include any contract team members who are on a monthly retainer or any team members who are considered part-time or full-time employees. </li>
//               </ul>
//               <ul>
//                 <li>When entering your team expenses, enter a quick total estimate for how much you typically pay your team members each month (including employee taxes, if applicable).  Here’s a quick video that provides more information. <a href="/" onClick={(e) => {
//                   e.preventDefault()
//                   setOpenLightbox(true);

//                 }}>Watch the video.</a></li>
//               </ul>
//               <ul>
//                 <li>For example, a business’s team expenses could include:
//                   <ul>
//                     <li>Virtual Assistant ($1000)</li>
//                     <li>Social Media Manager ($2500)</li>
//                     <li>Director of Ops ($4500)</li>
//                     <li>CPA ($250)</li>
//                   </ul>
//                   <p>Total: $8250</p>
//                 </li>
//               </ul>
//             </div>
//             <div className="team-expenses-notes">
//               <h2>2 notes</h2>
//               <div className="team-expenses-notes-row">
//                 <div className="item">
//                   <p>If you are currently on your business’s payroll, do NOT include yourself in this number. You’ll be asked to fill out your personal pay needs in a later question.</p>
//                 </div>
//                 <div className="item">
//                   <p>If you currently do not have team members, our calculator requires a <b>$500 minimum</b> so we can make sure we’re anticipating your need to hire to sustainably grow your business.</p>
//                 </div>
//               </div>
//             </div>
//             {openLightbox && <LightBox src="https://geek-tasks.com/team-expenses.mp4" vttSrc={teamExpensesVtt} clickHandler={setOpenLightbox} />}
//             <div className='d-flex'>
//               <div className='input-form'>
//                 <h3>When you're ready, share your team's monthly costs with us.</h3>
//                 {errors.length ? <div className="d-flex" style={{ marginBottom: "10px" }}>
//                   {errors.map(err => <span className="invalid-feedback span-control">
//                     {err}
//                   </span>)}
//                 </div> : <></>}
//                 <input onChange={handleInput} type='text' className='form-control' placeholder='' name="teams_month_cost" value={formData.teams_month_cost} />
//                 <span className="invalid-feedback span-control">
//                   {formError.teams_month_cost}
//                 </span>
//                 <p>*Need help gathering your numbers?  Grab our easy-peasy mini-course <a href="http://meganhale.co/numbers" target="_blank" rel="noreferrer">right here!</a></p>
//               </div>
//             </div>
//             <div className='btn-group'>
//               <button className='btn btn-primary-outline' onClick={(e) => {
//                 e.preventDefault();
//                 handleStep(prevStep)
//               }}>Back</button>
//               <button className='btn btn-primary' onClick={nextStep} disabled={disabled}>Next</button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export const StepFive = ({ step, formData, formError, handleStep, handleInput, setFormError }) => {
//   const [disabled, setDisabled] = useState(false);
//   const [openLightbox, setOpenLightbox] = useState(false);
//   const [errors, setErrors] = useState([]);
//   const prevStep = step - 1;
//   const nextStep = async () => {
//     let check = 1;
//     let tempError = formError;
//     if (formData.month_invest === "" || formData.month_invest === 0 || formData.month_invest < 250) {
//       check = 0;
//       tempError = {
//         ...tempError,
//         month_invest: "Please input a valid numerical number $250 or greater"
//       }
//     } else if (!formData.month_invest.match(numericalRegex)) {
//       check = 0;
//       tempError = {
//         ...tempError,
//         month_invest: "Please input a valid numerical number $250 or greater"
//       }
//     }

//     if (check) {
//       setDisabled(true);
//       const resp = await investmentExpenses(formData);
//       if (resp && resp.error) {
//         let errors = [];
//         if (typeof resp.error === 'string') {
//           errors.push(resp.error);
//         } else {
//           if (Object.keys(resp.error).length) {
//             for (let err of Object.keys(resp.error)) {
//               errors.push(resp.error[err]);
//             }
//           }
//         }
//         setErrors(errors);
//         setDisabled(false);
//       } else {
//         setFormError({
//           ...formError,
//           month_invest: ""
//         });
//         handleStep(step + 1)
//       }
//     } else {
//       setFormError(tempError);
//     }
//   }

//   useEffect(() => {
//     if (formData.identifier === "") {
//       handleStep(1)
//     }
//   }, [formData.identifier])
//   useEffect(() => {
//     window.scrollTo(0, 0)
//   }, [])
//   return (
//     <div className='container'>
//       <div className='step-6 steps active'>
//         <div className='step-container'>
//           <a href='/' className='back-step' onClick={(e) => {
//             e.preventDefault();
//             handleStep(prevStep)
//           }}> <img alt="go back" src={BackArrow} /> Back</a>
//           <div className='team-expenses monthly-investment'>

//             <h3 className='h2'>2. What are your monthly reinvestment expenses for {formData.company_name}?</h3>
//             <p>We consider reinvestment expenses as costs that are important for your overall business growth and expansion including professional development courses, attending conferences, etc. To help, here is a video that briefly walks you through a few examples of what investment expenses could be for your company. <a href="/" onClick={(e) => {
//               e.preventDefault()
//               setOpenLightbox(true);

//             }}>Watch the video.</a></p>
//             <div className="team-expenses-row">
//               <ul>
//                 <li>When entering your monthly investment expenses, we typically suggest totaling up all the investments you can think of off the top of your head <b>for the past year</b> and then divide that number by 12 to get your monthly cost.</li>
//                 <li>We want this number to be reflective of what you’ve reinvested back into your business <b>for the past 12 months</b> to establish a good baseline.</li>
//               </ul>
//               <ul>
//                 <li>As a reminder, reinvestment expenses are purchases made that are NOT necessary for the overall function of your business, but are intentionally made to help you get to your next level.  </li>
//               </ul>
//               <ul>
//                 <li>For example, reinvestment expenses for a business owner could include:
//                   <ul>
//                     <li>Coaching certification ($12K)</li>
//                     <li>Mastermind ($10K)</li>
//                     <li>Self-paced course ($500)</li>
//                     <li>New website ($5K)</li>
//                     <li>Yearly Total = $27,500</li>
//                   </ul>
//                   <p><b>Monthly Total would be $27,500/12 = $2292</b></p>
//                 </li>

//               </ul>
//             </div>
//             <div className="team-expenses-notes">
//               <h2>2 notes</h2>
//               <div className="team-expenses-notes-row">
//                 <div className="item">
//                   <p>Purchases made for softwares such as Zoom, Canva, Thrivecart, Quickbooks, Convertkit, Membervault, Zapier, etc are considered part of your business expenses which you’ll be asked to total up on the following page.</p>
//                 </div>
//                 <div className="item">
//                   <p>If you currently do not have any reinvestment expenses, our calculator requires a <b>$250 minimum</b> to ensure we’re considering this key financial need we all have as entrepreneurs.  </p>
//                 </div>
//               </div>
//             </div>


//             {openLightbox && <LightBox src="https://geek-tasks.com/investment-expenses.mp4" vttSrc={investmentExpensesVtt} clickHandler={setOpenLightbox} />}
//             <div className='d-flex'>
//               <div className='input-form'>
//                 <h3>When you're ready, share your monthly investment details with us.</h3>
//                 {errors.length ? <div className="d-flex" style={{ marginBottom: "10px" }}>
//                   {errors.map(err => <span className="invalid-feedback span-control">
//                     {err}
//                   </span>)}
//                 </div> : <></>}
//                 <input onChange={handleInput} type='text' className='form-control' placeholder='' name="month_invest" value={formData.month_invest} />
//                 <span className="invalid-feedback span-control">
//                   {formError.month_invest}
//                 </span>
//                 <p>*Need help gathering your numbers?  Grab our easy-peasy mini-course <a href="http://meganhale.co/numbers" target="_blank" rel="noreferrer">right here!</a></p>
//               </div>
//             </div>
//             <div className='btn-group'>
//               <button className='btn btn-primary-outline' onClick={(e) => {
//                 e.preventDefault();
//                 handleStep(prevStep)
//               }}>Back</button>
//               <button className='btn btn-primary' onClick={nextStep} disabled={disabled}>Next</button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export const StepSix = ({ step, formData, formError, handleStep, handleInput, setFormError }) => {
//   const [disabled, setDisabled] = useState(false);
//   const [openLightbox, setOpenLightbox] = useState(false);
//   const [errors, setErrors] = useState([]);
//   const prevStep = step - 1;
//   const nextStep = async () => {
//     let check = 1;
//     let tempError = formError;
//     if (formData.month_expenses === "") {
//       check = 0;
//       tempError = {
//         ...tempError,
//         month_expenses: "Please input a valid numerical number."
//       }
//     } else if (!formData.month_expenses.match(numericalRegex)) {
//       check = 0;
//       tempError = {
//         ...tempError,
//         month_expenses: "Please input a valid numerical number."
//       }
//     }

//     if (check) {
//       setDisabled(true);
//       const resp = await businessExpenses(formData);
//       if (resp && resp.error) {
//         let errors = [];
//         if (typeof resp.error === 'string') {
//           errors.push(resp.error);
//         } else {
//           if (Object.keys(resp.error).length) {
//             for (let err of Object.keys(resp.error)) {
//               errors.push(resp.error[err]);
//             }
//           }
//         }
//         setErrors(errors);
//         setDisabled(false);
//       } else {
//         setFormError({
//           ...formError,
//           month_expenses: ""
//         });
//         handleStep(step + 1)
//       }
//     } else {
//       setFormError(tempError);
//     }
//   }
//   useEffect(() => {
//     window.scrollTo(0, 0)
//   }, [])
//   return (
//     <div className='container'>
//       <div className='step-7 steps active'>
//         <div className='step-container'>
//           <a href="/" className='back-step' onClick={(e) => {
//             e.preventDefault();
//             handleStep(prevStep)
//           }}> <img src={BackArrow} alt="go back" /> Back</a>
//           <div className='team-expenses monthly-investment'>

//             <h3 className='h2'>3. What are your monthly business expenses for {formData.company_name}?</h3>
//             <p>For this calculation, you want to consider monthly business expenses that do not include your salary, your team's salary, or any investments in professional development. <a href="/" onClick={(e) => {
//               e.preventDefault()
//               setOpenLightbox(true);

//             }}>Watch the video.</a></p>


//             {openLightbox && <LightBox vttSrc={businessExpensesVtt} src="https://geek-tasks.com/business-expenses.mp4" vttSrc={businessExpensesVtt} clickHandler={setOpenLightbox} />}
//             <div className="team-expenses-row">
//               <ul>
//                 <li>These expenses usually include your tech stack, utilities, etc. and other monthly write offs that are typically considered essential for your overall company to function.</li>
//               </ul>
//               <ul>
//                 <li>For example, a business’s monthly operational expenses could include:
//                   <ul>
//                     <li>Zoom ($44)</li>
//                     <li>Canva ($15)</li>
//                     <li>Active Campaign ($73)</li>
//                     <li>Adobe ($11)</li>
//                     <li>Slack ($28)</li>
//                     <li>Quickbooks ($19)</li>
//                     <li>Zapier ($42)</li>
//                     <li>Fresh Flowers ($128)</li>
//                     <li>Liability Insurance ($158)</li>
//                     <li>Descript ($15)</li>
//                     <li>Manychat ($10)</li>
//                     <li>Circle ($99)</li>
//                   </ul>
//                   <p><b>Total: $642
//                   </b></p>
//                 </li>

//               </ul>
//             </div>
//             <div className='d-flex'>
//               <div className='input-form'>
//                 <h3>When you’re ready, you can enter your monthly business expenses right here. </h3>
//                 {errors.length ? <div className="d-flex" style={{ marginBottom: "10px" }}>
//                   {errors.map(err => <span className="invalid-feedback span-control">
//                     {err}
//                   </span>)}
//                 </div> : <></>}
//                 <input onChange={handleInput} type='text' className='form-control' placeholder='' name="month_expenses" value={formData.month_expenses} />
//                 <span className="d-flex invalid-feedback span-control">
//                   {formError.month_expenses}
//                 </span>
//                 <p>*Need help gathering your numbers?  Grab our easy-peasy mini-course <a href="http://meganhale.co/numbers" target="_blank" rel="noreferrer">right here!</a></p>
//               </div>
//             </div>
//             <div className='btn-group'>
//               <button className='btn btn-primary-outline' onClick={(e) => {
//                 e.preventDefault();
//                 handleStep(prevStep)
//               }}>Back</button>
//               <button className='btn btn-primary' onClick={nextStep} disabled={disabled}>Next</button>
//             </div>
//           </div >
//         </div >
//       </div >
//     </div >
//   );
// };

// export const StepSeven = ({ step, formData, formError, handleStep, handleInput, setFormError }) => {
//   const [disabled, setDisabled] = useState(false);
//   const [openLightbox, setOpenLightbox] = useState(false);
//   const [errors, setErrors] = useState([]);
//   const prevStep = step - 1;
//   const nextStep = async () => {
//     let check = 1;
//     let tempError = formError;
//     if (formData.month_personal_expenses === "") {
//       check = 0;
//       tempError = {
//         ...tempError,
//         month_personal_expenses: "Please input a valid numerical number."
//       }
//     } else if (!formData.month_personal_expenses.match(numericalRegex)) {
//       check = 0;
//       tempError = {
//         ...tempError,
//         month_personal_expenses: "Please input a valid numerical number."
//       }
//     }

//     if (check) {
//       setDisabled(true);
//       const resp = await personalExpenses(formData);
//       if (resp && resp.error) {
//         let errors = [];
//         if (typeof resp.error === 'string') {
//           errors.push(resp.error);
//         } else {
//           if (Object.keys(resp.error).length) {
//             for (let err of Object.keys(resp.error)) {
//               errors.push(resp.error[err]);
//             }
//           }
//         }
//         setErrors(errors);
//         setDisabled(false);
//       } else {
//         setFormError({
//           ...formError,
//           month_personal_expenses: ""
//         });
//         handleStep(step + 1)
//       }
//     } else {
//       setFormError(tempError);
//     }
//   }
//   useEffect(() => {
//     window.scrollTo(0, 0)
//   }, [])
//   return (
//     <div className='container'>
//       <div className='step-8 steps active'>
//         <div className='step-container'>
//           <a href='/' className='back-step' onClick={(e) => {
//             e.preventDefault();
//             handleStep(prevStep)
//           }}> <img src={BackArrow} alt="go back" /> Back</a>
//           <div className='team-expenses monthly-investment'>

//             <h3 className='h2'>4. {formData.name}, what is the realistic monthly salary you would like to pay yourself to cover your personal expenses?</h3>
//             <p>Personal expenses plays a big role in the overall financial health of your business, especially if you're running a small business or are self-employed.  We have a quick video with helpful tips — feel free to  <a href="/" onClick={(e) => {
//               e.preventDefault()
//               setOpenLightbox(true);

//             }}>watch it</a>, or you can skip it and enter the minimum salary you need to cover your existing living costs. </p>

//             {openLightbox && <LightBox src="https://geek-tasks.com/personal-pay.mp4" vttSrc={personalPayVtt} clickHandler={setOpenLightbox} />}
//             <div className="team-expenses-notes">
//               <h2>Note</h2>
//               <div className="team-expenses-notes-row">
//                 <div className="item">
//                   <p>Your minimum salary is the amount that needs to come into your household after taxes (i.e. your net pay).</p>
//                 </div>
//               </div>
//             </div>
//             <div className='d-flex'>
//               <div className='input-form'>
//                 <h3>When you’re ready, enter your <u>monthly</u> minimum salary that needs to be coming into your household.</h3>
//                 {errors.length ? <div className="d-flex" style={{ marginBottom: "10px" }}>
//                   {errors.map(err => <span className="invalid-feedback span-control">
//                     {err}
//                   </span>)}
//                 </div> : <></>}
//                 <input onChange={handleInput} type='text' className='form-control' placeholder='' name="month_personal_expenses" value={formData.month_personal_expenses} />
//                 <span className="d-flex invalid-feedback span-control">
//                   {formError.month_personal_expenses}
//                 </span>
//                 <p>*Have questions about calculating this number?  Grab our easy-peasy mini-course <a href="http://meganhale.co/numbers" target="_blank" rel="noreferrer">right here!</a></p>
//               </div>
//             </div>
//             <div className='btn-group'>
//               <button className='btn btn-primary-outline' onClick={(e) => {
//                 e.preventDefault();
//                 handleStep(prevStep)
//               }}>Back</button>
//               <button className='btn btn-primary' onClick={nextStep} disabled={disabled}>Next</button>
//             </div>
//           </div >
//         </div >
//       </div >
//     </div >
//   );
// };

// export const StepEight = ({ step, formData, handleStep }) => {
//   const prevStep = step - 1;
//   const [errors, setErrors] = useState([]);
//   const [playerState, setPlayerState] = useState({
//     playing: false,
//     controls: false,
//     disabledNext: true
//   })
//   const nextStep = async () => {
//     setPlayerState({
//       ...playerState,
//       disabledNext: true
//     })
//     const resp = await finalStep(formData);
//     if (resp && resp.error) {
//       let errors = [];
//       if (typeof resp.error === 'string') {
//         errors.push(resp.error);
//       } else {
//         if (Object.keys(resp.error).length) {
//           for (let err of Object.keys(resp.error)) {
//             errors.push(resp.error[err]);
//           }
//         }
//       }
//       setErrors(errors);
//       setPlayerState({
//         ...playerState,
//         disabledNext: false
//       })
//     } else {
//       handleStep(step + 1)
//     }
//   }
//   useEffect(() => {
//     window.scrollTo(0, 0)
//   }, [])
//   return (
//     <div className='container'>
//       <div className='step-9 steps active'>
//         <div className='step-container'>
//           <a href="/" onClick={(e) => {
//             e.preventDefault();
//             handleStep(prevStep)
//           }} className='back-step' > <img src={BackArrow} alt="go back" /> Back</a>
//           <div className='final-video'>
//             <h3>Hang Tight, {formData.name}! Your Insights Are Brewing.</h3>
//             <div className='video-box'>
               
//               <ReactPlayer width='100%' height='100%' playsinline={true} url='https://geek-tasks.com/mvr-7-key.mp4' playing={playerState.playing} controls={playerState.controls} 
//               config={{ 
//                 file: {
//                     tracks: [
//                         {
//                           kind: 'subtitles',
//                           src: mvrKeyNeedsVtt,
//                           srcLang: 'en',
//                           default: true,
//                         }
//                       ], 
//                     attributes: { controlsList: 'nodownload noplaybackrate' } },
//              }} onReady={() => setPlayerState({
//                 ...playerState,
//                 playing: true,
//                 controls: true
//               })
//               } onEnded={() => setPlayerState({
//                 ...playerState,
//                 disabledNext: false
//               })} />
              
//             </div>
//             <button className='btn btn-primary' onClick={nextStep} >Reveal My Insights</button>
//             <br/>
//             <div className="number_keys">
//               <p>The number you’re about to see is your Minimum Viable Revenue that’s 100% unique to your business and key financial needs!</p>
//               <div className="number_keys_box">
//                 <h4>The 7 key needs we consider when calculating your MVR are these: </h4>
//                 <ol>
//                   <li>Your Team Expenses</li>
//                   <li>Your Reinvestment Expenses</li>
//                   <li>Your Business Expenses</li>
//                   <li>Your Personal Pay</li>
//                   <li>Your Taxes</li>
//                   <li>Your Business Savings</li>
//                   <li>Your Ability to Give Back</li>
//                 </ol>
//               </div>
//               <p>The number on the following page will reveal exactly how much your business needs to earn so each of your 7 key needs are fully funded.  Watch the video above for a more detailed description.</p>
//             </div>
//             <h3 >Ready to see your number, {formData.name}? Your Financial Insights Are Ready. </h3>
//             {errors.length ? <div className="d-flex" style={{ marginBottom: "10px" }}>
//               {errors.map(err => <span className="invalid-feedback span-control">
//                 {err}
//               </span>)}
//             </div> : <></>}
//             <button className='btn btn-primary' onClick={nextStep} >Reveal My Insights</button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export const StepNine = ({ step, formData, handleStep }) => {
//   const [revenue, setRevenue] = useState({});
//   const prevStep = step - 1;
//   const nextStep = step + 1;
//   useEffect(() => {
//     async function fetchData() {
//       const resp = await showRevenue(formData);
//       setRevenue(resp.data.data);
//     }
//     fetchData();
//     window.scrollTo(0, 0)
//   }, [])
//   return (
//     <div className='step-10 steps result-screen1 active'>
//       <div className='container'>
//         <div className='step-container'>
//           <a href="/" onClick={(e) => {
//             e.preventDefault();
//             handleStep(prevStep)
//           }} className='back-step' > <img src={BackArrow} alt="go back" /> Back</a>
//           <h2>Woohoo! 🎉 {formData.name}! <br /> Here is your monthly Minimum Viable Revenue.</h2>
//           <h3>${formatter.format(Math.round(revenue?.calculations?.total))}</h3>
//           <div className="result_con">
//             <p>We’re going to go deeper into this number and exactly how we calculated it on the next 2 pages.
//               <span>Ready to see all the dreamy details included in your number? </span>
//               You’re so close to massive financial clarity!!</p>
//           </div>
//           <button className='btn btn-primary' onClick={() => handleStep(nextStep)}>Learn More</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export const StepTen = ({ step, formData, formError, handleStep, handleInput, setFormError }) => {
//   const [errors, setErrors] = useState([]);
//   const [revenue, setRevenue] = useState({});
//   const prevStep = step - 1;
//   const [playerState, setPlayerState] = useState({
//     playing: false,
//     controls: false,
//     disabledNext: true
//   });

//   const nextStep = async () => {
//     let check = 1;
//     let tempError = formError;
//     if (formData.feeling === "") {
//       check = 0;
//       tempError = {
//         ...tempError,
//         feeling: "Please select how you are feeling to move forward."
//       }
//     }

//     if (check) {
//       setPlayerState({
//         ...playerState,
//         disabledNext: true
//       })
//       const resp = await feelings(formData);
//       if (resp && resp.error) {
//         let errors = [];
//         if (typeof resp.error === 'string') {
//           errors.push(resp.error);
//         } else {
//           if (Object.keys(resp.error).length) {
//             for (let err of Object.keys(resp.error)) {
//               errors.push(resp.error[err]);
//             }
//           }
//         }
//         setErrors(errors);
//         setPlayerState({
//           ...playerState,
//           disabledNext: false
//         })
//       } else {
//         setFormError({
//           ...formError,
//           feeling: ""
//         });
//         notify(formData);
//         handleStep(step + 1)
//       }
//     } else {
//       setFormError(tempError);
//     }
//   }
//   useEffect(() => {
//     async function fetchData() {
//       const resp = await showRevenue(formData);
//       setRevenue(resp.data.data);
//     }
//     fetchData();
//     window.scrollTo(0, 0)
//   }, [])
//   useEffect(() => {
//     window.scrollTo(0, 0)
//   }, [])
//   return (
//     <div className='step-11 steps result-felling active'>
//       <div className='container'>
//         <a href="/" onClick={(e) => {
//           e.preventDefault();
//           handleStep(prevStep)
//         }} className='back-step' > <img src={BackArrow} alt="go back" /> Back</a>
//         <div className='step-container'>
//           <h2>Before we dive in to learn more about your monthly Minimum Viable Revenue -</h2>
//           <h3>${formatter.format(Math.round(revenue?.calculations?.total))}</h3>
//           <div className='v-box'>
//             <p>Let’s do a quick emotional check-in.  Watch the full video to receive your personal financial insights.</p>
//             {/* normalizeVtt */}
//             <ReactPlayer width='100%' playsinline={true} height='100%' url='https://geek-tasks.com/result-page-2.mp4' playing={playerState.playing} controls={playerState.controls} config={{ file: { 
//                  tracks: [
//                     {
//                       kind: 'subtitles',
//                       src: normalizeVtt,
//                       srcLang: 'en',
//                       default: true,
//                     }
//                   ], 
//                 attributes: { controlsList: 'nodownload noplaybackrate' } }, }} onReady={() => setPlayerState({
//               ...playerState,
//               playing: true,
//               controls: true
//             })
//             } onEnded={() => setPlayerState({
//               ...playerState,
//               disabledNext: false
//             })} />
//           </div>
//           <div className='custom-radio-wrap'>
//             <h5>How are you feeling after seeing your MVR?</h5>
//             {errors.length ? <div className="d-flex" style={{ marginBottom: "10px" }}>
//               {errors.map(err => <span className="invalid-feedback span-control">
//                 {err}
//               </span>)}
//             </div> : <></>}
//             <div className='d-flex'>
//               <div className='custom-radio'>
//                 <input onChange={handleInput} type='radio' id="Excited" name="feeling" value="Excited" checked={formData.feeling === "Excited" ? true : false} />
//                 <label htmlFor="Excited">Excited</label>
//               </div>
//               <div className='custom-radio'>
//                 <input onChange={handleInput} type='radio' id="Overwhelmed" name="feeling" value="Overwhelmed" checked={formData.feeling === "Overwhelmed" ? true : false} />
//                 <label htmlFor="Overwhelmed">Overwhelmed</label>
//               </div>
//               <div className='custom-radio'>
//                 <input onChange={handleInput} type='radio' id="Both" name="feeling" value="Both excited and overwhelmed" checked={formData.feeling === "Both excited and overwhelmed" ? true : false} />
//                 <label htmlFor="Both">Both</label>
//               </div>
//             </div>
//             <span className="d-flex invalid-feedback span-control">
//               {formError.feeling}
//             </span>
//           </div>
//           <button className='btn btn-primary' onClick={nextStep}>See My Result!</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export const StepEleven = ({ formData, step, handleStep }) => {
//   const [revenue, setRevenue] = useState({});
//   const [secondVideoPlay, setSecondVideoPlay] = useState(false)
//   const prevStep = step - 1;

//   useEffect(() => {
//     async function fetchData() {
//       const resp = await showRevenue(formData);
//       setRevenue(resp.data.data);
//     }
//     fetchData();
//     localStorage.removeItem("dreamMoney");
//     window.scrollTo(0, 0)
//   }, [])
//   return (
//     <div className='result-wrap active'>

//       <div className='result-banner-wrap'>
//         <div className='container'>
//           {formData && <a href="/" onClick={(e) => {
//             e.preventDefault();
//             handleStep(prevStep)
//           }} className='back-step' > <img src={BackArrow} alt="go back" /> Back</a>}
//           <div className='text-center'>
//             <div className='revenu'><p>💌  We’ve Just Emailed Your Minimum Viable Revenue Number For Your Records</p></div>
//           </div>
//           <div className='result-banner-row'>
//             <div className='result-banner-heading'>
//               <h1>Your Personal Financial Insights</h1>
//             </div>
//             <div className='result-banner-content'>
//               <p>Based on your custom inputs, your Minimum Viable Revenue for your business is:</p>

//               <h3>${formatter.format(Math.round(revenue?.calculations?.total))}/month</h3>
//               <h3>or ${formatter.format(Math.round(revenue?.calculations?.total * 12))}/year</h3>
//               <p>This is your first important milestone that unlocks financial stability inside your business!</p>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className='money-calc-wrap'>
//         <div className='container'>
//           <div className='money-calc-head'>
//             <h2>Let’s take a closer look at how your number breaks down behind the scenes. </h2>
//             {/* <p>Here's a snapshot of where we see potential savings</p> */}
//           </div>
//           <div className='money-calc-row'>
//             <div className='money-calc-head-row'>
//               <div className='fin-need'>
//                 <p>Your 7 Key Financial Needs</p>
//               </div>
//               <div className='fin-need-pers'>
//                 <p>% Allocated Toward Each Need</p>
//               </div>
//               <div className='fin-need-equal'>
//               </div>
//               <div className='fin-need-dollar'>
//                 <p>$ Allocated  <br />  Toward Each Need</p>
//               </div>
//             </div>
//             <div className='money-calc-fin-row'>
//               <div className='fin-need'>
//                 <p>1. Personal Salary</p>
//               </div>
//               <div className='fin-need-pers'>
//                 <p>{Math.round(revenue?.calculations?.values?.personal_expenses?.percent)}%</p>
//                 {/* <p>{Math.round(revenue?.calculations?.values?.taxes?.percent)}%</p> */}
//               </div>
//               <div className='fin-need-equal'>
//                 <img src={EqualLine} alt="equal sign" />
//               </div>
//               <div className='fin-need-dollar'>
              
//                 <p>${Math.round(revenue?.calculations?.values?.personal_expenses?.value)}</p>
//                 {/* <p>${Math.round(revenue?.calculations?.values?.taxes?.value)}</p> */}
//               </div>
//             </div>
//             <div className='money-calc-fin-row'>
//               <div className='fin-need'>
//                 <p>2. Business Expenses </p>
//               </div>
//               <div className='fin-need-pers'>
//                 <p>{Math.round(revenue?.calculations?.values?.business_expenses?.percent)}%</p>
//               </div>
//               <div className='fin-need-equal'>
//                 <img src={EqualLine} alt="equal sign" />
//               </div>
//               <div className='fin-need-dollar'>
//                 <p>${Math.round(revenue?.calculations?.values?.business_expenses?.value)}</p>
//               </div>
//             </div>
//             <div className='money-calc-fin-row'>
//               <div className='fin-need'>
//                 <p>3. Federal & State Tax <span class="tooltip"><img src={InfoIcon} alt="" /><span class="tooltiptext">Your taxes are based on what you pay yourself after business expense write-offs. Not total revenue earned by your company.</span></span> </p>
//               </div>
//               <div className='fin-need-pers'>
//                 <p>{Math.round(revenue?.calculations?.values?.taxes?.percent)}%</p>
//               </div>
//               <div className='fin-need-equal'>
//                 <img src={EqualLine} alt="equal sign" />
//               </div>
//               <div className='fin-need-dollar'>
//                 <p>${Math.round(revenue?.calculations?.values?.taxes?.value)}</p>
//               </div>
//             </div>
//             <div className='money-calc-fin-row'>
//               <div className='fin-need'>
//                 <p>4. Team Expenses <span class="tooltip"><img src={InfoIcon} alt="" /><span class="tooltiptext">We require a minimum of $500 even if you don’t currently have team members.  That’s because to sustainably grow your business, you’ll need to hire at least a little bit of support.  This minimum helps ensure you’re moving in that direction.
//                 </span></span></p>
//               </div>
//               <div className='fin-need-pers'>
//                 <p>{Math.round(revenue?.calculations?.values?.team_expenses?.percent)}%</p>
//               </div>
//               <div className='fin-need-equal'>
//                 <img src={EqualLine} alt="equal sign" />
//               </div>
//               <div className='fin-need-dollar'>
//                 <p>${Math.round(revenue?.calculations?.values?.team_expenses?.value)}</p>
//               </div>
//             </div>
//             <div className='money-calc-fin-row'>
//               <div className='fin-need'>
//                 <p>5. Reinvestment Expenses <span class="tooltip"><img src={InfoIcon} alt="" /><span class="tooltiptext">We require a minimum of $250 to ensure you’re accounting for this key need we all have as business owners.  The adage is true - it takes money to make money and every year, you’ll have key reinvestment needs for your business.</span></span></p>
//               </div>
//               <div className='fin-need-pers'>
//                 <p>{Math.round(revenue?.calculations?.values?.investment?.percent)}%</p>
//               </div>
//               <div className='fin-need-equal'>
//                 <img src={EqualLine} alt="equal sign" />
//               </div>
//               <div className='fin-need-dollar'>
//                 <p>${Math.round(revenue?.calculations?.values?.investment?.value)}</p>
//               </div>
//             </div>
//             <div className='money-calc-fin-row'>
//               <div className='fin-need'>
//                 <p>6. Business Savings</p>
//               </div>
//               <div className='fin-need-pers'>
//                 <p>{Math.round(revenue?.calculations?.values?.profit?.percent)}%</p>
//               </div>
//               <div className='fin-need-equal'>
//                 <img src={EqualLine} alt="" />
//               </div>
//               <div className='fin-need-dollar'>
//                 <p>${Math.round(revenue?.calculations?.values?.profit?.value)}</p>
//               </div>
//             </div>

//             <div className='money-calc-fin-row'>
//               <div className='fin-need'>
//                 <p>7. Giving Back <span class="tooltip"><img src={InfoIcon} alt="" /><span class="tooltiptext">2% is based on thousands of data points for what a sustainable give-back looks like in most small businesses.</span></span></p>
//               </div>
//               <div className='fin-need-pers'>
//                 <p>{Math.round(revenue?.calculations?.values?.giving?.percent)}%</p>
//               </div>
//               <div className='fin-need-equal'>
//                 <img src={EqualLine} alt="equal sign" />
//               </div>
//               <div className='fin-need-dollar'>
//                 <p>${Math.round(revenue?.calculations?.values?.giving?.value)}</p>
//               </div>
//             </div>
//             <div className='money-calc-fin-row'>
//               <div className='fin-need'>
//                 <p>8. Debt Payback <span class="tooltip"><img src={InfoIcon} alt="" /><span class="tooltiptext">Most business owners have some level of debt they’ve incurred as part of their start up capital to grow their businesses. Around here, we normalize that as part of the growth process.</span></span></p>
//               </div>
//               <div className='fin-need-pers'>
//                 <p>{Math.round(revenue?.calculations?.values?.debt_repayment?.percent)}%</p>
//               </div>
//               <div className='fin-need-equal'>
//                 <img src={EqualLine} alt="" />
//               </div>
//               <div className='fin-need-dollar'>
//                 <p>${Math.round(revenue?.calculations?.values?.debt_repayment?.value)}</p>
//               </div>
//             </div>
//             <div className='money-calc-fin-row total'>
//               <div className='fin-need'>
//                 <p>TOTAL <span class="tooltip totalTooltip"><img src={InfoIcon} alt="" /><span class="tooltiptext">Your MVR is based on your current expenses.  It does not necessarily reflect healthy margins. Healthy margins refer to specific ranges for a few key expenses.  For instance, we recommend your team margin be below 25% to prioritize the financial health of your business.</span></span></p>
//               </div>
//               <div className='fin-need-pers'>
//                 <p>100%</p>
//               </div>
//               <div className='fin-need-equal'>
//                 <img src={EqualLine} alt="Equal line" />
//               </div>
//               <div className='fin-need-dollar'>
//                 <p>${formatter.format(Math.round(revenue?.calculations?.total))}/mo</p>
//               </div>
//             </div>
//           </div>
//           <div className="money-calc-footer">
//             <p>*Want to make sure your MVR reflects your accurate numbers? <br />  Grab our easy-peasy <a href="http://meganhale.co/numbers" target="_blank" rel="noreferrer">mini-course</a> that will help you get radically precise!</p>
//           </div>
//         </div>
//       </div>
//       <div className='rich-move-wrap'>
//         <div className='container'>
//           <div className='rich-move-head'>
//             <p>Now that you have this financial clarity -</p>
//             <h2>Can we say <span>“Rich Move”?</span></h2>
//           </div>
//           <h3>Your next step is <strong>mapping out your sustainable revenue plan</strong> for reaching your Minimum Viable Revenue!</h3>
//         </div>
//       </div>
//       <div className='here_how_wrap'>
//         <div className='container'>
//           <div className='here_how_row'>
//             <div className='here_how_head'>
//               <h2>HERE’s How</h2>
//               <img src={LongArrow} className='img-fluid hide-m' alt="" />
//               <img src={LongArrowD} className='img-fluid show-m' alt="" />
//             </div>
//             <div className='here_how_video'>
//               <ReactPlayer width='100%' height='100%' url={Videofull} playing={false} controls={true} config={{ file: { attributes: { controlsList: 'nodownload noplaybackrate' } }, }} style={{ marginBottom: "0px" }} onEnded={() => setSecondVideoPlay(true)} />

//             </div>
//           </div>
//         </div>
//       </div>
//       <div className='introducing-wrap'>
//         <div className='container'>
//           <div className='introducing-box'>
//             <h2>Introducing Funded.</h2>
//             <h4>The Only Course That Helps You Fund One Whole Year Of Sustainable Cash Flow With One Key Offer That Simplifies & Streamlines Your Business.</h4>
//             <p>Explore Your Dream Money Era!</p>
//             <a className='btn btn-white' href="https://meganhale.co/funded" target="_blank" rel="noreferrer">Let’s Enter</a>
//           </div>
//         </div>

//       </div>
//       <div className="copyright">
//         <p>© 2024 Megan Hale, LLC.</p>
//       </div>
//     </div >
//   );
// };