import { useEffect, useRef, useState } from "react";
import ReactPlayer from 'react-player';
import CloseIcon from '../../img/charm_cross.svg';
import { StepEight, StepEleven, StepFive, StepFour, StepNine, StepOne, StepSeven, StepSix, StepTen, StepThree, StepTwo } from "./StepForms";
import Header from "../Header";
import Footer from "../Footer";
import { useIdleTimer } from 'react-idle-timer';
import VideoGather from '../../img/gather-your-number.mp4';

const idleContent = <div className='team-expenses'>
    <h3 className='h2'>Do you need help gathering your numbers?</h3>
    <div className="video-modals">
          <video src={VideoGather} preload="auto" autoplay="" controls="true" loop="" ></video>
  </div>
  <br />
    <div className="team-expenses-notes">
        <p>Click here to check out our quick mini course to gather your personal expenses.</p>
    </div></div>;

export const LightBox = ({ vttSrc, src = "", Wrapper = 'div', zIndex = 100, clickHandler, content, closeButtonText = "Close" }) => {
    return (
        <Wrapper>
            <div className='modal-wrapper' style={{
                position: 'fixed',
                top: '0',
                left: '0',
                height: '100vh',

                width: '100vw',
                cursor: 'pointer',
                zIndex
            }}>
                <div className='close-icon' onClick={() => clickHandler(false, false)}><img src={CloseIcon} alt="close" /></div>

                <div className='video-modal'>
                    {src !== "" &&
                        <div className="container">
                            <ReactPlayer width='100%' height='auto' url={src} playsinline={false} loop={false} playing={true} controls={true}
                                config={{
                                    file: {
                                        tracks: [
                                            {
                                                kind: 'subtitles',
                                                src: vttSrc,
                                                srcLang: 'en',
                                                default: true,
                                            }
                                        ],
                                        attributes: { controlsList: 'track nodownload noplaybackrate' }
                                    }
                                }}
                            >
                            </ReactPlayer>
                        </div>
                    }
                    <div className="modal-body">
                        {content}
                        <button className='btn btn-primary btn-close' onClick={() => clickHandler(false, true)} >{closeButtonText}</button>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

const DreamMoneyForm = () => {
    const [step, setStep] = useState(1);
    const [idleToggle, setIdleToggle] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        company_name: "",
        email: "",
        teams_month_cost: "",
        month_invest: "",
        month_expenses: "",
        month_personal_expenses: "",
        feeling: "",
        identifier: ""
    })
    const [formError, setFormError] = useState({
        name: "",
        company_name: "",
        email: "",
        teams_month_cost: "",
        month_invest: "",
        month_expenses: "",
        month_personal_expenses: ""
    })
    const dreamMoneyStorage = useRef("");

    const handleStep = (newStep, identifier = undefined) => {
        if (identifier !== undefined) {
            setFormData({
                ...formData,
                identifier
            })
        }
        setStep(newStep)
    }

    const handleInput = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
    }

    const handleUnload = (e) => {
        if (dreamMoneyStorage.current === "") {
            localStorage.removeItem("dreamMoney");
        } else {
            localStorage.setItem("dreamMoney", dreamMoneyStorage.current);
        }
    }

    const onIdle = () => {
        setIdleToggle(true);
    }

    const closeClickHandler = (value, url) => {
        setIdleToggle(value);
        if (url) {
            window.open('https://meganhale.co/numbers', '_blank');
        }
    }

    useIdleTimer({
        onIdle,
        timeout: 5 * 60 * 1000,
        throttle: 500
    })

    useEffect(() => {
        let dreamMoneyForm = localStorage.getItem("dreamMoney")
        if (dreamMoneyForm) {
            dreamMoneyForm = JSON.parse(dreamMoneyForm);
            if (dreamMoneyForm.step) {
                setStep(dreamMoneyForm.step);
                setFormData(dreamMoneyForm.formData);
                // setFormError(dreamMoneyForm.formError);
            }
        }
        window.addEventListener('beforeunload', handleUnload)
    }, []);

    useEffect(() => {
        if (step < 11) {
            dreamMoneyStorage.current = JSON.stringify({
                formData,
                formError,
                step
            })
        } else {
            dreamMoneyStorage.current = "";
        }
    }, [step, formData, formError])

    return (
        <>
            <Header></Header>
            <div className='calculator-wrap'>
                {idleToggle && <LightBox content={idleContent} clickHandler={closeClickHandler} closeButtonText="Get it here for $17" />}
                {/* <div className='container'> */}
                {step === 1 && <StepOne step={step} handleStep={handleStep} handleInput={handleInput} formData={formData} formError={formError} setFormError={setFormError} />}
                {step === 2 && <StepTwo step={step} handleStep={handleStep} handleInput={handleInput} formData={formData} formError={formError} setFormError={setFormError} />}
                {step === 3 && <StepThree step={step} handleStep={handleStep} handleInput={handleInput} formData={formData} formError={formError} setFormError={setFormError} />}
                {step === 4 && <StepFour step={step} handleStep={handleStep} handleInput={handleInput} formData={formData} formError={formError} setFormError={setFormError} />}
                {step === 5 && <StepFive step={step} handleStep={handleStep} handleInput={handleInput} formData={formData} formError={formError} setFormError={setFormError} />}
                {/* {step === 6 && <StepSix step={step} handleStep={handleStep} handleInput={handleInput} formData={formData} formError={formError} setFormError={setFormError} />}
                {step === 7 && <StepSeven step={step} handleStep={handleStep} handleInput={handleInput} formData={formData} formError={formError} setFormError={setFormError} />} */}
                {step === 6 && <StepEight step={step} handleStep={handleStep} handleInput={handleInput} formData={formData} formError={formError} setFormError={setFormError} />}
                {step === 7 && <StepNine step={step} handleStep={handleStep} handleInput={handleInput} formData={formData} formError={formError} setFormError={setFormError} />}
                {step === 8 && <StepTen step={step} handleStep={handleStep} handleInput={handleInput} formData={formData} formError={formError} setFormError={setFormError} />}
                {step === 9 && <StepEleven step={step} handleStep={handleStep} handleInput={handleInput} formData={formData} formError={formError} setFormError={setFormError} />}
                {/* </div> */}
            </div>
            <Footer></Footer>
        </>
    );
}

export default DreamMoneyForm;