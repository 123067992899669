import React, { useEffect, useState } from "react";
import Logo from "../img/website_ideation_20.png";


function Header(){
    const [isActive, setActive] = useState(false);

    const toggleClass = () => {
      setActive(!isActive);
    };
    return (
        <div id="header" className="header_wrap">
          <div className="container">
          <div className="header_row">
             <div className="header_item header-fill">
                <ul className="menu">
                    <li><a href="https://meganhale.co/about">About</a></li>
                    <li><a href="https://meganhale.co/programs">programs</a></li>
                    <li><a href="https://meganhale.co/podcast">podcast</a></li>
                </ul>
             </div>
             <div className="header_item  header-logo">
                <a href="https://meganhale.co"><img src={Logo} alt="Logo" /></a>
             </div>
             <div className="header_item header-fill last">
             <ul className="menu">
                    <li><a href="http://app.meganhale.co/">Dream Money</a></li>
                    <li><a href="https://meganhale.co/start-here">START HERE  </a></li>
                    <li><a href="https://meganhale.co/login" target="_blank">LOGIN</a></li>
                </ul>
             </div>
             <div className="menu-btn" >
                  <a href="javascript:;" className={isActive ? 'header__nav-button toogle-menu':  'header__nav-button' } 
      onClick={toggleClass}><span></span><span></span><span></span></a>
               </div>
            
            </div>
            
               <div className={isActive ? 'mobile_menu open-menu':  'mobile_menu' } >
                 <div className="flex-m">
                 <div className="left-part">
                 <ul>
                   <li><a href="https://meganhale.co">Home</a></li> 
                   <li><a href="https://meganhale.co/about">About</a></li>
                    <li><a href="https://meganhale.co/programs">programs</a></li>
                    <li><a href="http://app.meganhale.co/">Dream Money</a></li>
                    <li><a href="https://meganhale.co/podcast">podcast</a></li>
                    <li><a href="https://meganhale.co/contact">contact</a></li>

                 </ul>
                 </div>
                 <div className="right-part">
                 <a href="https://meganhale.co/start-here" className="start_link">START HERE  </a>
                    <a href="https://meganhale.co/login" target="_blank" className="login_link">LOGIN</a>

                 <ul className="social-links">
                        <li><a href="https://www.tiktok.com/@meganhaleco" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M433.29,150.28c-13.21-1.22-34.28-5.4-52.61-20.21a164.93,164.93,0,0,1-27.47-32.68l-.64-1-.62-1-.14-.21c-.69-1.12-1.35-2.23-2-3.32-2.62-4.5-4.75-8.68-5.29-9.73-1.91-3.82-3.48-7.31-4.76-10.37-.34-.93-.67-1.89-1-2.92A54.43,54.43,0,0,1,336.56,53l-70.32.4.84,277.68c0,14.9-12.19,40-34.61,50.87-16.22,7.88-38.32,7.19-50.67.72l-.09-.05-.06,0,0,0a74,74,0,0,1-8.89-5.62,62.36,62.36,0,0,1-9.41-8.8c-3.5-4-10.71-12.57-13.11-25.7-1.89-10.33.28-18.48,1.34-23.35.85-3.87,5.85-24.89,26.59-37.94,21.7-13.65,43.91-8.06,47.54-7.08.28-17.86,1.05-64.79,1.15-70.41-10.74-1.81-46-6.37-82.41,14-39.25,21.89-53.34,57.85-57.12,68a131,131,0,0,0-8.2,49.21,122.67,122.67,0,0,0,37.24,86.72,148.78,148.78,0,0,0,21,16.79q2.58,2,5.41,3.72a69.53,69.53,0,0,0,14.59,6.79c5.4,2.32,62.76,25.78,118.78-8.39,56.58-34.5,61.55-97.13,61.9-102.79V189.44A150.19,150.19,0,0,0,434.54,220C434.43,213.39,433.64,166.92,433.29,150.28Z"></path></svg></a></li>
                        <li><a href="https://instagram.com/meganhaleco" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M338,54.63H174c-62.72,0-114,51.32-114,114V343.33c0,62.72,51.32,114,114,114H338c62.72,0,114-51.32,114-114V168.67C452,105.94,400.68,54.63,338,54.63Zm83.91,288.71A84.24,84.24,0,0,1,338,427.24H174a84.24,84.24,0,0,1-83.91-83.91V168.67A84.24,84.24,0,0,1,174,84.76H338a84.24,84.24,0,0,1,83.91,83.91Z"></path><path d="M255.77,148.18A107.94,107.94,0,1,0,363.71,256.13,107.94,107.94,0,0,0,255.77,148.18Zm-.52,178.71a70.68,70.68,0,1,1,70.68-70.68A70.68,70.68,0,0,1,255.25,326.9Z"></path><circle cx="365.44" cy="146.5" r="25.56"></circle></svg></a></li>
                        <li><a href="https://facebook.com/meganhale.ma" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M288 192v-38.1c0-17.2 3.8-25.9 30.5-25.9H352V64h-55.9c-68.5 0-91.1 31.4-91.1 85.3V192h-45v64h45v192h83V256h56.4l7.6-64h-64z"></path></svg></a></li>
                        
                    </ul>

                 </div>
                 </div>
               </div>
          </div>
        </div>

    );
}

export default Header;