import App from "./App";
import { createBrowserRouter } from "react-router-dom";
import DreamMoneyForm from "./components/DreamMoneyForm";
import DreamMoneyFormNew from "./components/DreamMoneyFormNew";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                index: true,
                element: <DreamMoneyFormNew />,
            },
            {
                path: "/dream-money-form",
                element: <DreamMoneyForm />,
            },
            {
                path: "/dream-money-form-new",
                element: <DreamMoneyFormNew />,
            },
        ],
    },
]);